/* eslint-disable */
<template>
  <div style="display:block;margin:30px;padding-bottom:15px;padding-top:15px;background-color:#f5f6ff">

    <div style="display:flex;width:100%;justify-content: center;padding-bottom: 10px;" >
      <img id="logo" height="100px" style="height:0px"/>
    </div>

    <div style="display:flex;width:100%">
      <div class="entreprise_col_gauche" >
        Nom de l'entreprise
      </div>
      <div class="entreprise_col_right">
        {{entreprise.nom}}
      </div>
    </div>

    <div style="display:flex;width:100%">
      <div class="entreprise_col_gauche" >
        Adresse postale
      </div>
      <div class="entreprise_col_right">
        {{entreprise.adresse}}
      </div>
    </div>

    <div style="display:flex;width:100%">      
      <div class="entreprise_col_gauche" >
        Nature du projet
      </div>
      <div class="entreprise_col_right">
        {{entreprise.projet}}
      </div>
    </div>

    <div style="display:flex;width:100%">
      <div class="entreprise_col_gauche" >
        Maître d'ouvrage
      </div>
      <div class="entreprise_col_right">
        {{entreprise.maitre}}
      </div>
    </div>

    <div style="display:flex;width:100%">
      <div class="entreprise_col_gauche" >
        Mail de contact
      </div>
      <div class="entreprise_col_right">
        {{entreprise.mail}}
      </div>
    </div>

    <div style="display:flex;width:100%">
      <div class="entreprise_col_gauche" >
        Téléphone
      </div>
      <div class="entreprise_col_right">
        {{entreprise.tel}}
      </div>
    </div>

    <div style="display:flex;width:100%">
      <div class="entreprise_col_gauche" >
        URL du site web
      </div>
      <div class="entreprise_col_right">
        {{entreprise.web}}
      </div>
    </div>

    <div style="display:flex;width:100%">
      <div class="entreprise_col_gauche" >
        Commentaire
      </div>
      <div class="entreprise_col_right">
        {{entreprise.commentaire}}
      </div>
    </div>

  </div>
</template>

<script>
        export default {
        name: 'Entreprise',
        components: {},
        props :['entreprise','date_rapport']
        }

</script>