import axios from "axios";

export default {
  methods: {

    get_detail_chantier(code) {
      return axios
        .get('https://europe-west9-fntp-eee.cloudfunctions.net/'+process.env.VUE_APP_PREFIX_ENDPOINT+'detail_chantier?web=y&code_chantier=["'+code+'"]')
        .then(
          (response) => {
            return response.data[0];
          },
          (error) => {
            return error.response;
          }
        );
    },

    set_commentaire(chantier, galerie, commentaire) {
      let form = new FormData();
      form.append('code_chantier', chantier);
      form.append('code_galerie', galerie);
      form.append('commentaire', commentaire);
      return axios
        .post('https://europe-west9-fntp-eee.cloudfunctions.net/'+process.env.VUE_APP_PREFIX_ENDPOINT+'ajouter_commentaire_plante', form)
        .then(
          (response) => {
            return response.data;
          },
          (error) => {
            return error.response;
          }
        );
    }
  }
};
